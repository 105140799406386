<template>
  <div>
    <hero-alt :img="header.img" :title="header.title" />

    <faq-view :title="header.title" :data="content" v-on:update="onUpdate" />
  </div>
</template>

<script>
import api from "@/plugins/api";

export default {
  name: "Notice",

  metaInfo: { title: "Notice" },

  data: () => ({
    header: {
      title: "Foire aux Questions",
      img:
        "https://image.freepik.com/photos-gratuite/ouvrir-voler-vieux-livres_1232-2096.jpg"
    },

    translation: {
      available: ["fr"],
      current: "fr"
    },

    content: []
  }),

  async mounted() {
    this.content = await api.help.getAll(this.translation.current);
  },

  methods: {
    async onUpdate(event) {
      if (event.type === "delete") {
        await api.help.delete(event.id);
      }
      else if (event.type === "put") {
        await api.help.update(event.id, event.content);
      }
      else if (event.type === "post") {
        await api.help.create(event.content);
      }
      this.content = await api.help.getAll(this.translation.current);
    }
  },

  components: {
    HeroAlt: () => import("@/components/mixins/HeroAlt"),
    FaqView: () => import("@/components/QCMEditor")
  }
};
</script>
